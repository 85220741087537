const env = process.env.REACT_APP_ENVIROMENT;
let url;
let config;
let configDev = {
  // Your app's URL
  DS_APP_URL: "http://3.64.206.84:3036",
  DS_CLIENT_ID: "e09381de-988d-4bf4-867c-551cb0d8d6b6",
  IMPLICIT_SCOPES: "signature",
  // DocuSign Identity server
  DS_IDP: "https://account-d.docusign.com",
  // Your private API CORS proxies
  // Add additional entries for the demo and prod platforms that your
  // users' accounts need
  DS_API_CORS_PROXIES: {
    "https://na2.docusign.net": "https://staging.ordema.net",
    "https://na3.docusign.net": "https://staging.ordema.net",
    "https://demo.docusign.net": "https://demo.docusign.net",
  },
};

if (env === "prod") {
  url = "https://lapi.ordema.net";
  configDev.DS_APP_URL = 'https://docusign.ordema.net';
  config = configDev;
} else if (env === "staging") {
  url = "https://api.ordema.net";
  configDev.DS_APP_URL = "https://docustaging.ordema.net";
  config = configDev;
} else {
  url = "https://lapi.ordema.net";
  configDev.DS_APP_URL = "https://docusign.ordema.net";
  config = configDev;
}

export { url, config };