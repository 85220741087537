
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { config } from './environment';
import { ToastContainer, toast } from 'react-toastify';
import DocService from './OAuthImplicit';
import logoIcon from './_assets/ordema.png';
import { url } from './environment';
import './App.scss';

const isObjectEmpty = (obj) => {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
};
const App = () => {
  const [queryData, setQueryData] = useState(queryString.parse(window.location.search));
  const [user, setUser] = useState({});
  const [errLoginUser, setErrLoginUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let query = queryString.parse(window.location.search)
    if (query.userId) {
      setQueryData(query);
      window.localStorage.setItem('callbackData', JSON.stringify(query));
    }
    if (window.location.search && window.location.search === '?error=logout_request') {
      let temp = JSON.parse(window.localStorage.getItem('callbackData'));
      window.location = `${config.DS_APP_URL}/?userId=${temp.userId}&&agreementId=${temp.agreementId}&&email=${temp.email}`;
    }
  }, [window.location.search]);


  useEffect(() => {
    if (queryData && queryData.userId) {
      startAuthentication()
    }

    return () => {
      window.localStorage.removeItem('callbackData');
    }
  }, []);

  useEffect(() => {
    if (window.location.hash) {
      getHash(window.location.hash);
    }

  }, [window.location.hash]);


  const startAuthentication = async () => {
    setLoading(true);
    let res = await DocService.login();
    if (res && res.error) setUser(res);
  }
  const getHash = async (hash) => {
    setLoading(true);
    let res = await DocService.receiveHash(hash);
    if (res && !res.error) {
      setUser(res)
      await saveTokenToDB(res);
    }
    setLoading(false);
  }

  const saveTokenToDB = async (results) => {
    let temp = window.localStorage.getItem('callbackData');
    let data = {
      envelopeCreator: results,
      data: JSON.parse(temp)
    }
    setQueryData(JSON.parse(temp));
    if (results.accessToken) {
      let res = await fetch(`${url}/api/docusign/token`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then((response) => {
        return response.json();
      }).then((data) => {
        setLoading(false);
        return data
      });
      if (res.error) {
        toast.error(`${res.error.message}`)
        setErrLoginUser(res.error.data.email)
      } else {
        window.close();
      }
    }

  }
  const logout = async () => {
    await DocService.logout();
    setUser({});
    setErrLoginUser(null)
  }
  const Title = () => {
    return (
      <div className="loader">
        <img src={logoIcon} alt="Ordema" />
      </div>
    );
  }

  return (
    <div className="App">
      <div>
        <ToastContainer />
        <Title />
        {loading && <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
        {!isObjectEmpty(user) && <div className="infoMsg" >
          {errLoginUser ? <>
            <h3>You trying to log in with <span>{user.email}</span> </h3>
            <h3>You need to log in with  <span>{errLoginUser}</span> </h3>
          </> : <h3>Logged with <span>{user.email}</span> </h3>}
          <button className="btnPrimary" onClick={logout}>Logout</button>
        </div>
        }
      </div>
    </div>
  );
}

export default App;
